/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ReactPageScroller from '../../lib/index';
import '../../static/styles/index.scss';
import Layout from '../../layouts/en';
import SEO from '../../components/seo';
import MainSection from '../../pages-sections/investor-relations-sections/main-section/main-section';
import Pagination from '../../components/pagination/pagination';
import LineIndicator from '../../components/line-indicator/line-indicator';
// import MobilePlaceholder from '../../components/mobilePlaceholder/mobilePlaceholder';
import GovernanceSection from '../../pages-sections/investor-relations-sections/governance-section/governance-section';
import FinancialsSection from '../../pages-sections/investor-relations-sections/financials-section/financials-section';
import StockInformationSection from '../../pages-sections/investor-relations-sections/stock-information-section/stock-information-section';
import CodeSection from '../../pages-sections/investor-relations-sections/code-section/code-section';
import ModelSection from '../../pages-sections/investor-relations-sections/model-section/model-section';
import PoliciesSection from '../../pages-sections/investor-relations-sections/policies-section/policies-section';
import MoreSection from '../../pages-sections/investor-relations-sections/more-section/more-section';
import ShareholdersSection from '../../pages-sections/investor-relations-sections/shareholders-section/shareholders-section';
import {
  returnNum,
  getPageData,
  setRtl,
  unsetRtl,
} from '../../helpers/helpers';
import useGoToPage from '../../hooks/useGoToPage';

const IndexPage = ({ location, data }) => {
  const locationLink = location.hash.length ? returnNum(location.hash) : 1;
  const [currentPage, setCurrentPage] = useState(locationLink);
  const [lineHeight, setLineHeight] = useState(0);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [windowSize, setWindowSize] = useState(0);
  const [showSections, setShowSections] = useState(false);
  const [reports, setReports] = useState(null);
  const [assets, setAssets] = useState(null);
  const wpDataAssets = data && data.allWordpressWpAssets.edges;
  const wpDataReports = data && data.allWordpressWpReports.edges;
  useEffect(() => {
    setAssets(wpDataAssets);
  }, [data]);
  const wpData = data && getPageData(data, 'investor-relations-ar');
  useEffect(() => {
    setRtl(location.pathname);
    return () => {
      unsetRtl();
    };
  }, []);

  useEffect(() => {
    const reportSliderData = [];
    let reportSlider = [];
    wpDataReports.forEach((item, i, arr) => {
      const { node: { acf: { file } } } = item;
      reportSlider.push(file);
      if ((i + 1) % 4 === 0) {
        reportSliderData.push(reportSlider);
        reportSlider = [];
      } else if (arr.length === i + 1) {
        reportSliderData.push(reportSlider);
        reportSlider = [];
      }
    });
    setReports(reportSliderData);
  }, [data, setReports, wpDataReports]);

  const isMobile = windowSize <= 1024;
  const MENU_ITEMS = [
    wpData.section_title_1,
    wpData.section_title_2,
    wpData.section_title_3,
    wpData.section_title_4,
    wpData.section_title_5,
    wpData.section_title_6,
    wpData.section_title_7,
  ];
  const [ref, goToPage] = useGoToPage(location, isMobile, MENU_ITEMS, currentPage);

  const onChangeSection = ({ index, name }) => {
    goToPage(index, name);
  };

  const handleWindowSizeChange = () => {
    const width = window.innerWidth || document.documentElement.clientWidth
  || document.body.clientWidth;
    setWindowSize(width);
  };

  const handleWindowScroll = () => {
    const scrollBlocker = window.pageYOffset > 0 || window.pageYOffset < 0;

    setCanScrollUp(scrollBlocker);
    return true;
  };

  useEffect(() => {
    handleWindowSizeChange(); // Set width
    window.addEventListener('resize', handleWindowSizeChange);
    if (!isMobile) {
      document.addEventListener('scroll', handleWindowScroll, { passive: false });
      setShowSections(false);
    } else {
      setShowSections(true);
    }
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isMobile]);

  useEffect(() => {
    const activePaginationButton = document.querySelector('button[data-activeitem="active"]');
    const topPosition = activePaginationButton
      ? activePaginationButton.getBoundingClientRect().top + 20 : 486;
    setLineHeight(topPosition);
    setTimeout(() => {
      if (showSections !== (currentPage === MENU_ITEMS.length + 1)) {
        setShowSections(currentPage === MENU_ITEMS.length + 1);
      }
    }, 1000); // time for render sections that are out of pageScroller
  }, [currentPage, windowSize]);

  if (windowSize === 0) {
    return (<></>);
  }

  const renderSections = () => (
    [
      <MainSection
        isMobile={isMobile}
        activeSection={currentPage}
        title={wpData.section_1_name}
        data={
        {
          title: wpData.section_1_title,
          titleBold: wpData.section_1_title_bold,
          image: wpData.section_1_background_image,
        }
      }
      />,
      <GovernanceSection
        isMobile={isMobile}
        activeSection={currentPage}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_1}
          />
        )}
        sectionName={wpData.section_title_1}
        data={
        {
          title: wpData.section_2_title,
          text1: wpData.section_2_text_paragraph_1,
          text2: wpData.section_2_text_paragraph_2,
          text3: wpData.section_2_text_paragraph_3,
        }
      }
      />,
      <FinancialsSection
        isMobile={isMobile}
        activeSection={currentPage}
        title={wpData.section_title_2}
        index={3}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_2}
          />
        )}
        sectionName={wpData.section_title_2}
        data={reports}
      />,
      <StockInformationSection
        isMobile={isMobile}
        activeSection={currentPage}
        title={wpData.section_title_3}
        index={4}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_3}
          />
        )}
        sectionName={wpData.section_title_3}
        location={location}
        charts={assets}
        data={
        {
          char_1: wpData.character_1,
          char_2: wpData.character_2,
          text: wpData.section_4_text,
        }
      }
      />,
      <ShareholdersSection
        isMobile={isMobile}
        activeSection={currentPage}
        title={wpData.section_title_4}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_4}
          />
        )}
        sectionName={wpData.section_title_4}
        data={
        {
          col11: wpData.section_5_col_1_title,
          col21: wpData.section_5_col_2_title,
          col31: wpData.section_5_col_3_title,
          col12: wpData.section_5_col_1_text_1,
          col22: wpData.section_5_col_2_text_1,
          col32: wpData.section_5_col_3_text_1,
          col13: wpData.section_5_col_1_text_2,
          col23: wpData.section_5_col_2_text_2,
          col33: wpData.section_5_col_3_text_2,
          col14: wpData.section_5_col_1_text_3,
          col24: wpData.section_5_col_2_text_3,
          col34: wpData.section_5_col_3_text_3,
          col15: wpData.section_5_col_1_text_4,
          col25: wpData.section_5_col_2_text_4,
          col35: wpData.section_5_col_3_text_4,
          text: wpData.section_5_text,
          text2: wpData.section_5_text_2,
          text3: wpData.section_5_text_3,
          text4: wpData.section_5_text_4,
        }
      }
      />,
      <CodeSection
        isMobile={isMobile}
        activeSection={currentPage}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_5}
          />
        )}
        sectionName={wpData.section_title_5}
        data={
        {
          title: wpData.section_6_title,
          text1: wpData.section_6_text_paragraph_1,
          text2: wpData.section_6_text_paragraph_2,
          text3: wpData.section_6_text_paragraph_3,
          image: wpData.section_6_background_image,
        }
      }
      />,
      <ModelSection
        isMobile={isMobile}
        activeSection={currentPage}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_6}
          />
        )}
        sectionName={wpData.section_title_6}
        data={
         {
           title: wpData.section_7_title,
           text1: wpData.section_7_text_paragraph_1,
           text2: wpData.section_7_text_paragraph_2,
           listItem1: wpData.section_7_text_paragraph_2_list_item_1,
           listItem2: wpData.section_7_text_paragraph_2_list_item_2,
           listItem3: wpData.section_7_text_paragraph_2_list_item_3,
         }
       }
      />,
      <PoliciesSection
        isMobile={isMobile}
        activeSection={currentPage}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_7}
          />
        )}
        sectionName={wpData.section_title_7}
        data={
          {
            title: wpData.section_8_title,
            texts: [
              wpData.section_8_text_1,
              wpData.section_8_text_2,
              wpData.section_8_text_3,
              wpData.section_8_text_4,
              wpData.section_8_text_5,
            ],
          }
        }
      />,
    ]
  );
  return (
    <>
      <Layout
        currentSection={currentPage}
        isMobile={isMobile}
        sectionsCount={MENU_ITEMS.length + 1}
        location={location}
        changeHandler={onChangeSection}
        showFooter={showSections}
        footerData={
          {
            footerLink1: wpData.footer_link_1,
            footerLink2: wpData.footer_link_2,
            footerLink3: wpData.footer_link_3,
            footerLink4: wpData.footer_link_4,
            footerLink5: wpData.footer_link_5,
            footerLink6: wpData.footer_link_6,
            footerLink7: wpData.footer_link_7,
            footerEmailLabel: wpData.footer_email_label,
            footerEmail: wpData.footer_email,
            footerPhoneLabel: wpData.footer_phone_label,
            footerPhone: wpData.footer_phone,
            copyright: wpData.copyright,
          }
        }
      >
        <SEO title={wpData.section_1_name} />
        <div data-currentPage={`currentPage_${currentPage}`}>
          {isMobile ? (
            <>
              <div ref={ref}>
                {renderSections().map((section) => section)}
              </div>
              <Pagination
                canScrollUp={canScrollUp}
                menuItems={MENU_ITEMS}
                changeHandler={onChangeSection}
                activeItem={currentPage}
              />
            </>
          ) : (
            <ReactPageScroller
              ref={ref}
              pageOnChange={(num) => setCurrentPage(num)}
              customPageNumber={currentPage}
              transitionTimingFunction="cubic-bezier(.435,.01,.135,1)"
              blockScrollUp={canScrollUp}
              scrollBlocker={showSections}
              pagination={(
                <Pagination
                  canScrollUp={canScrollUp}
                  menuItems={MENU_ITEMS}
                  changeHandler={onChangeSection}
                  activeItem={currentPage}
                />
            )}
            >
              {renderSections().map((section) => section)}
            </ReactPageScroller>
          )}
          <LineIndicator
            height={lineHeight}
            activeItem={currentPage}
          />
          { (showSections || isMobile)
          && (
          <MoreSection
            activeSection={currentPage}
            data={
              {
                title: wpData.section_9_title,
                link: wpData.section_9_link,
              }
            }
          />
          )}
        </div>
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};
IndexPage.defaultProps = {
  data: null,
};
export default IndexPage;
export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          acf {

            section_title_1
            section_title_2
            section_title_3
            section_title_4
            section_title_5
            section_title_6
            section_title_7

            section_1_title
            section_1_title_bold
            section_1_name
            section_1_background_image {
              alt
              url
            }
            section_2_title
            section_2_text_paragraph_1
            section_2_text_paragraph_2
            section_2_text_paragraph_3
            
            section_3_file_1 {
              title
              url
              caption
            }
            section_3_file_2 {
              title
              url
              caption
            }
            section_3_file_3 {
              title
              url
              caption
            }
            section_3_file_4 {
              title
              url
              caption
            }
            section_3_file_5 {
              title
              url
              caption
            }
            section_3_file_6 {
              title
              url
              caption
            }
            section_3_file_7 {
              title
              url
              caption
            }
            section_3_file_8 {
              title
              url
              caption
            }
            section_3_file_9 {
              title
              url
              caption
            }
            section_3_file_10 {
              title
              url
              caption
            }
            section_3_file_11 {
              title
              url
              caption
            }
            section_3_file_12 {
              title
              url
              caption
            }
            section_3_file_13 {
              title
              url
              caption
            }
            section_3_file_14 {
              title
              url
              caption
            }
            section_3_file_15 {
              title
              url
              caption
            }
            section_3_file_16 {
              title
              url
              caption
            }
            section_3_file_17 {
              title
              url
              caption
            }
            section_3_file_18 {
              title
              url
              caption
            }
            section_3_file_19 {
              title
              url
              caption
            }
            section_3_file_20 {
              title
              url
              caption
            }
            
            character_1
            character_2
            section_4_text
            
            section_5_col_1_title
            section_5_col_2_title
            section_5_col_3_title
            section_5_col_1_text_1
            section_5_col_2_text_1
            section_5_col_3_text_1

            section_5_col_1_text_2
            section_5_col_2_text_2
            section_5_col_3_text_2
            
            section_5_col_1_text_3
            section_5_col_2_text_3
            section_5_col_3_text_3

            section_5_col_1_text_4
            section_5_col_2_text_4
            section_5_col_3_text_4
            
            section_5_text
            section_5_text_2
            section_5_text_3
            section_5_text_4

            section_6_title
            section_6_text_paragraph_1
            section_6_text_paragraph_2
            section_6_text_paragraph_3
            section_6_background_image {
              alt
              url
            }

            section_7_title
            section_7_text_paragraph_1
            section_7_text_paragraph_2
            section_7_text_paragraph_2_list_item_1
            section_7_text_paragraph_2_list_item_2
            section_7_text_paragraph_2_list_item_3

            section_8_title
            section_8_text_1
            section_8_text_2
            section_8_text_3
            section_8_text_4
            section_8_text_5

            section_9_title
            section_9_link

            footer_link_1
            footer_link_2
            footer_link_3
            footer_link_4
            footer_link_5
            footer_link_6
            footer_link_7
            footer_email_label
            footer_email
            footer_phone_label
            footer_phone
            copyright
          }
          title
          slug
        }
      }
    }
    allWordpressWpAssets(
      filter: {
        categories: {
          eq: 19
        }
      }
    ) {
      edges {
        node {
          acf {
            date
            number
          }
        }
      }
    }
    allWordpressWpReports(
      filter: {
        categories: {
          eq: 22
        }
      }
      sort: {
          fields: modified,
          order: DESC
      }
    ) {
      edges {
        node {
          acf {
            file {
              title
              url
              caption
            }
          }
        }
      }
    }
  }`;
